import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

// Hooks
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

import { PageMeta } from "../components/pageMeta";
import { PageBanner } from "../components/blockZone/blocks";
import { ProjectGridItem } from "../components/ui";
import Layout from "../components/layout";

const ProjectCataegory = ({ data: { page, projects } }) => {
  const { title, featuredImage, meta } = page || {};
  const { edges } = projects || {};

  console.log(page.title);

  return (
    <Layout>
      <PageBanner image={featuredImage} title={title} />
      <div
        className={cx("", {
          "bg-community": page.slug.current === "community",
          "bg-residential": page.slug.current === "residential",
          "bg-commercial": page.slug.current === "commercial",
        })}
      >
        <div className="px-gutter py-16 lg:py-32 xl:py-40">
          <section className="grid grid-cols-1 md:grid-cols-2 gap-16 lg:gap-x-14 lg:gap-y-28">
            {edges.map((item) => {
              return (
                <>
                  <ProjectGridItem {...item.node} />
                </>
              );
            })}
          </section>
        </div>
      </div>
      <PageMeta {...meta} />
    </Layout>
  );
};

export default ProjectCataegory;

export const pageQuery = graphql`
  query projectCategoryQuery($slug: String!) {
    projects: allSanityProject(
      sort: { fields: order }
      filter: { category: { slug: { current: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          order
          category {
            title
            id
          }
          shortDesc
          featuredImage {
            ...ImageWithPreview
          }
        }
      }
    }
    page: sanityProjectCategory(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      title
      slug {
        current
      }
      featuredImage {
        ...Image
      }
    }
  }
`;
